import { z } from 'zod';

export const voyadoContactSchema = z.object({
    firstName: z.string().min(1, 'First name is required'),
    lastName: z.string().min(1, 'Last name is required'),
    email: z
        .string()
        .min(1, 'Email is required')
        .email('Invalid email address'),
    mobilePhone: z
        .string()
        .min(1, 'Phone number is required')
        .regex(
            /^\+\d+$/,
            "Phone number must start with '+' and only contain digits",
        ),
    company: z.string().min(1, 'Company is required'),
    companyTitle: z.string().min(1, 'Company title is required'),
    street: z.string().optional(),
    zipCode: z.string().optional(),
    city: z.string().optional(),
    countryCode: z.string().optional(),
});

export type VoyadoContact = z.infer<typeof voyadoContactSchema>;
